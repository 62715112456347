const FMB_XHAIN_TERASSEN = `mapbox://styles/hejco/ckm3lgekg9jky17rznm5kn8bd`;

const config = {
  email: 'info@fixmyberlin.de',
  map: {
    style: FMB_XHAIN_TERASSEN,
  },
};

export default config;
