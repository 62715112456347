import styled from 'styled-components';

export default styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;

  img {
    height: 100%;
  }
`;
