import { ConfigStaticpages } from '../types/ConfigStaticPages';

export const staticpages: ConfigStaticpages[] = [
  {
    key: 'aachen-privacy',
    route: '/datenschutz',
  },
  {
    key: 'aachen-imprint',
    route: '/impressum',
  },
  {
    key: 'aachen-contact',
    route: '/kontakt',
  },
  {
    key: 'newsletter-thank-you',
    route: '/newsletter-danke',
  },
];
